import React, { useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import 'bootstrap/dist/css/bootstrap.min.css';

const Churn = () => {
  const [features, setFeatures] = useState({
    gender: '',
    seniorcitizen: '',
    partner: '',
    tenure: '',
    phoneservice: '',
    contract: '',
    paperlessbilling: '',
    dependents: '',
    deviceprotection: '',
    onlinesecurity: '',
    multiplelines: '',
    techsupport: '',
    streamingmovies: '',
    streamingtv: '',
    onlinebackup: '',
    paymentmethod: '',
    internetservice: '',
    monthlycharges: '',
    totalcharges: '',
  });

  const [prediction, setPrediction] = useState(null);
  const [probability, setProbability] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFeatures(prevFeatures => ({ ...prevFeatures, [name]: value }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const featureArray = [
      parseInt(features.gender, 10),
      parseInt(features.tenure, 10),
      parseInt(features.seniorcitizen, 10),
      parseInt(features.partner, 10),
      parseInt(features.phoneservice, 10),
      parseInt(features.contract, 10),
      parseInt(features.paperlessbilling, 10),
      parseInt(features.dependents, 10),
      parseInt(features.deviceprotection, 10),
      parseInt(features.onlinesecurity, 10),
      parseInt(features.multiplelines, 10),
      parseInt(features.techsupport, 10),
      parseInt(features.streamingmovies, 10),
      parseInt(features.streamingtv, 10),
      parseInt(features.paymentmethod, 10),
      parseInt(features.internetservice, 10),
      parseInt(features.onlinebackup, 10),
      parseFloat(features.monthlycharges),
      parseFloat(features.totalcharges)
    ];

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/predict`, { features: featureArray });
      setPrediction(response.data.prediction);
      setProbability(response.data.probability);
    } catch (error) {
      console.error('Error making prediction:', error);
    }
  };

  return (
    <Container fluid="md" className="mb-5">
      <h1 className="mb-5">Customer Churn Prediction</h1>
      <Row>
        <Col>
          <Form data-bs-theme="dark">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Gender</Form.Label>
                <Form.Select name="gender" value={features.gender} onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">Male</option>
                  <option value="1">Female</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tenure</Form.Label>
                <Form.Control type="number" placeholder="34" name="tenure" value={features.tenure} min="0" onChange={handleInputChange} />
              </Form.Group>       
              <Form.Group className="mb-3">
                <Form.Label>Senior Citizen</Form.Label>
                <Form.Select value={features.seniorcitizen} name="seniorcitizen" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Dependents</Form.Label>
                <Form.Select value={features.dependents} name="dependents" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Online Security</Form.Label>
                <Form.Select value={features.onlinesecurity} name="onlinesecurity" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Online Backup</Form.Label>
                <Form.Select value={features.onlinebackup} name="onlinebackup" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">No internet service</option>
                  <option value="2">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Internet Service</Form.Label>
                <Form.Select value={features.internetservice} name="internetservice" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">DSL</option>
                  <option value="1">Fiber optic</option>
                  <option value="2">No</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Multiple Lines</Form.Label>
                <Form.Select value={features.multiplelines} name="multiplelines" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">No phone service</option>
                  <option value="2">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Device Protection</Form.Label>
                <Form.Select value={features.deviceprotection} name="deviceprotection" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">No internet servic</option>
                  <option value="2">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tech Support</Form.Label>
                <Form.Select value={features.techsupport} name="techsupport" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">No internet service</option>
                  <option value="2">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Streaming TV</Form.Label>
                <Form.Select value={features.streamingtv} name="streamingtv" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">No internet service</option>
                  <option value="2">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Streaming Movies</Form.Label>
                <Form.Select value={features.streamingmovies} name="streamingmovies" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">No internet service</option>
                  <option value="2">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Payment Method</Form.Label>
                <Form.Select value={features.paymentmethod} name="paymentmethod" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="2">Electronic check</option>
                  <option value="3">Mailed check</option>
                  <option value="0">Bank transfer (automatic)</option>
                  <option value="1">Credit card (automatic)</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Monthly Charges</Form.Label>
                <Form.Control type="number" placeholder="52.15" name="monthlycharges" value={features.monthlycharges} min="0" onChange={handleInputChange} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Total Charges</Form.Label>
                <Form.Control type="number" placeholder="1289.3" name="totalcharges" value={features.totalcharges} min="0" onChange={handleInputChange} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Partner</Form.Label>
                <Form.Select value={features.partner} name="partner" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Service</Form.Label>
                <Form.Select value={features.phoneservice} name="phoneservice" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Contract</Form.Label>
                <Form.Select value={features.contract} name="contract" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">Month-to-month</option>
                  <option value="1">One year</option>
                  <option value="2">Two years</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Paperless Billing</Form.Label>
                <Form.Select value={features.paperlessbilling} name="paperlessbilling" onChange={handleInputChange}>
                  <option>Select</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-4 mb-4" onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col md={9}>
          <div>
            <h2 className="mb-4">Prediction</h2>
            {
              prediction !== null && (
                <><p>Churn: {prediction === 0 ? 'No' : 'Yes'}</p>
                <p>Probability: {probability}</p></>
              )
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Churn;