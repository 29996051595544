import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './Home';
import Churn from './Churn';

export const App = () => {
    return (
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/churn" element={<Churn />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;